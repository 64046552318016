import React from 'react'
import "../Blog.css"

import "./syntax-highlighter.css"
import { useNavigate } from 'react-router-dom';


const BlogPost = ({ id, title, author, date, excerpt, content, parts }) => {
    const history = useNavigate();

    const handleOpenPost = (postid, post_title, post_author, post_date, post_excerpt, post_content, post_parts) => {

        history(`${postid}`, { state: { postid, post_title, post_author, post_date, post_excerpt, post_content, post_parts } });
    }

    return (
        <article className="blog-post" onClick={() => handleOpenPost(id, title, author, date, excerpt, content, parts)} >
            <header>
                <h2>{title}</h2>
                <div className="post-meta">
                    <span>By {author}</span>
                    <span>on {date}</span>
                </div>
            </header>
            <div className="post-excerpt">
                <p>{excerpt}</p>
            </div>
            <div className="post-content">
                {content.length > 100 ? content.slice(0, 100) + "..." : content}
            </div>
            {parts?.[parts.length - 1]?.tags && <div className='tags'>{parts?.[parts.length - 1]?.tags.map(tag => <span>{tag}</span>)}</div>}
        </article>
    )
}

export default BlogPost