import React, { useEffect, useState } from 'react';
import BlogPost from '../BlogPost/BlogPost';
import "./Blog.css";

const BlogList = ({ posts }) => {
    const [filteredPosts, setFilteredPosts] = useState(posts); // Filtered posts state
    const [selectedTag, setSelectedTag] = useState('All'); // Selected tag for filtering
    const [sortOrder, setSortOrder] = useState('recent'); // Sorting order
    const [uniqueTags, setUniqueTags] = useState([]); // Unique tags for filtering options

    useEffect(() => {
        // Extract unique tags from posts
        const tagsSet = new Set();
        posts.forEach((post) => {
            post.parts?.forEach((part) => {
                if (part.tags) {
                    part.tags.forEach((tag) => tagsSet.add(tag));
                }
            });
        });
        setUniqueTags(['All', ...Array.from(tagsSet)]);
    }, [posts]);

    useEffect(() => {
        let updatedPosts = [...posts];

        // Filter by selected tag
        if (selectedTag !== 'All') {
            updatedPosts = updatedPosts.filter((post) =>
                post.parts?.some((part) => part.tags?.includes(selectedTag))
            );
        }

        // Sort by date
        updatedPosts.sort((a, b) =>
            sortOrder === 'recent'
                ? new Date(b.date) - new Date(a.date)
                : new Date(a.date) - new Date(b.date)
        );

        setFilteredPosts(updatedPosts);
    }, [selectedTag, sortOrder, posts]);

    return (
        <div className="blog-container">
            <div className="blog-controls">
                <div className="filter">
                    <p className='filter-label'>Filter by Tag:</p>
                    <select
                        id="tagFilter"
                        value={selectedTag}
                        onChange={(e) => setSelectedTag(e.target.value)}
                    >
                        {uniqueTags.map((tag, index) => (
                            <option key={index} value={tag}>
                                {tag}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="sort">
                    <p className='filter-label'>Sort by:</p>
                    <select
                        id="sortOrder"
                        value={sortOrder}
                        onChange={(e) => setSortOrder(e.target.value)}
                    >
                        <option value="recent">Most Recent</option>
                        <option value="oldest">Oldest</option>
                    </select>
                </div>
            </div>


            {/* Blog Posts */}
            <section className="blog-list">
                {filteredPosts.map((post) => (
                    <BlogPost key={post.id} {...post} />
                ))}
            </section>
        </div>
    );
};

export default BlogList;
